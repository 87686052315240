export const rewards = [
  "Take a nap",
  "Visit library",
  "Read a book",
  "Sleep in",
  "Go to bed late",
  "Watch TV",
  "Watch a movie",
  "Take a selfie",
  "Spend time away from phone",
  "Spend time away from computer",
  "Take a break",
  "Eat lunch outside",
  "Eat away from your desk",
  "Take a bubble bath",
  "Take an epsom salt bath",
  "Donate something",
  "Post your progress",
  "Celebrate with friends",
  "Drive a beautiful neighbourhood",
  "Visit the park",
  "Have a picnic",
  "Give yourself a sticker",
  "Make a mark on a calendar",
  "Give yourself a ribbon",
  "Give yourself a trophy",
  "Plan a night out",
  "See an old friend",
  "Put up a magnet on the fridge",
  "Take a vacation",
  "Take a day off",
  "Buy a lottery ticket",
  "Put money aside",
  "Buy yourself a gift",
  "Subscribe to a magazine",
  "Take a picture",
  "Enjoy your favorite meal",
  "Enjoy your favorite dessert",
  "Have a snack",
  "Download a new app",
  "Buy a new song",
  "Celebrate by singing",
  "Get new clothes",
  "Get yourself something fancy",
  "Get a new plant",
  "Plant something",
  "Try a new shade of nail polish",
  "Try new food",
  "Try a new drink",
  "Enjoy your favorite drink",
  "Change your hair colour",
  "Get some flowers",
  "Get a new accessory",
  "Get a manicure",
  "Get a pedicure",
  "Get a fitness tracker",
  "Get new headphones",
  "Sign up for a charity walk",
  "Volunteer",
  "Get a massage",
  "Cook a new recipe",
  "Get a new hairstyle",
  "Take a cooking class",
  "Get some new shoes",
  "Get a new piercing",
  "Get a new tattoo",
  "Start a charm bracelet",
  "Get a new ring",
  "Plan a weekend getaway",
  "Adopt a pet",
  "Get a new pet toy",
  "Hire someone to clean your house",
  "Try a fun exercise class",
  "Book a personal trainer",
  "Get a professional portrait",
  "Get a new game",
  "Have family time",
  "Put on your old favorite cloths",
  "Get a new journal",
  "Enjoy your favorite music",
  "Light a candle",
  "Have a pajama day",
  "Have a cup of hot cocoa",
  "Have a game night",
  "Make your favorite cookies",
  "Make a fort",
  "Learn a new joke",
  "Play some cards",
  "Go for a bike ride",
  "Try a new coffee",
  "Jump for joy",
  "Do nothing",
];
